















































































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import GroupHallItemType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";
import { ImagePreview } from "vant"
import moment from "moment";
import {setColl} from "@/views/Group/Server";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";

@Component({name: "GroupHallItem",components:{ ScrollViewX }})
export default class GroupHallItem extends Vue implements GroupHallItemType{
    DefaultIcon = require("@/assets/icon/Basics/default.png")
    RightIcon = require("@/assets/icon/Basics/Right.png")
    AddIcon = require("@/assets/icon/Basics/Add.png")
    CollIcon = require("@/views/Group/Img/coll.png")
    A_CollIcon = require("@/views/Group/Img/A_coll.png")
    CommentIcon = require("@/views/Group/Img/comment.png")
    active = false

    handleClickPreview(index:number){
        ImagePreview({
            images:this.getImgList,
            startPosition:index,
            loop:true
        })
    }

    handleClickColl(){
        setColl(1,this.getData && this.getData.id).then(res=>{
            this.active = !this.active
        })
    }

    handleToPath(index:number){
        if ( index > 0 ){
            // 跳转参与 列表
            this.$router.push({
                name:"joinList",
                query:{
                    taskId:this.getData.id as string
                }
            })
        }else{
            //hall myIssue join
            if ( index < 0 ) GroupSucStore.SetGroupSucCommentShow(true);
            this.$router.push({
                name:"groupSuc",
                query:{
                    state:this.state as string,
                    taskId:this.getData.id as string
                }
            })
        }
    }

    get getPhoto(){
        if ( this.getData  && this.getData.headImg ){
            return this.getData.headImg
        }else {
            return this.DefaultIcon
        }
    }

    get getStartTime(){
        if ( this.getData && this.getData.startDate ){
            return this.getData.startDate.split(" ")[0]
        }else{
            return moment().format("YYYY-MM-DD")
        }
    }

    get getTime(){
        if ( this.getData && this.getData.createTime ){
            return this.getData.createTime.split("T")[0]
        }else{
            return moment().format("YYYY-MM-DD")
        }
    }

    get getImgList(){
        if ( this.getData && this.getData.img ){
            return this.getData.img.split(",")
        }else{
            return []
        }
    }

    get getAddList(){
        if ( this.getData && this.getData.teamUserInfo ){
            return this.getData.teamUserInfo
        } else{
            return []
        }
    }

    @Prop(String)
    state!:string

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
    @Watch("getData")
    changeData(newVal:any){
        if ( newVal && newVal.isCollect == 1 )this.active = true;
        else this.active = false;
    }
}
